import { routes as authRoutes } from "./authRoutes";
import { routes as exerciseRoutes } from "./exerciseRoutes";
import { routes as orderRoutes } from "./orderRoutes";
import { routes as patientRoutes } from "./patientRoutes";
import { routes as productRoutes } from "./productRoutes";
import { routes as providerRoutes } from "./providerRoutes";
import { routes as rendersRoutes } from "./rendersRoutes";
import { routes as stitchesRoutes } from "./stitchesRoutes";
import { routes as transitionRoutes } from "./transitionRoutes";
import { routes as videoRoutes } from "./videoRoutes";
import { routes as workoutRoutes } from "./workoutRoutes";

export type RouteItem = {
  path: string;
  element: React.ReactNode;
};

const privateRoutes: RouteItem[] = [
  ...workoutRoutes,
  ...exerciseRoutes,
  ...transitionRoutes,
  ...patientRoutes,
  ...orderRoutes,
  ...providerRoutes,
  ...videoRoutes,
  ...rendersRoutes,
  ...stitchesRoutes,
  ...productRoutes
];
const publicRoutes: RouteItem[] = [...authRoutes];

export { privateRoutes, publicRoutes };
